import {Column} from '../shared/models/column';
import {ColumnType} from '../shared/enums/column-type.enum';
import {Partner} from '../partners/partner';
import {Area} from '../areas/area';
import {Client} from '../clients/client';
import {Terminal} from '../terminals/terminal';
import {Tablet} from '../tablets/tablet';
import {Transaction} from '../transactions/transaction';
import {PosSession} from '../pos_sessions/pos_session';
// import { User } from '../signups/signup';
import {MerchantAccess} from '../merchant-accesses/merchant-access';
import {Merchant} from '../merchants/merchant';
import {BusinessPhoto} from '../business_photos/business_photo';
import {Membership} from '../memberships/membership';
import {User} from '../users/user';
import {Offer} from '../offers/offer';
import {Administrator} from '../administrators/administrator';

export class Business {
  // allColumns for DataTable
  static allColumns: Column[] = [
    new Column({name: '#', prop: 'id', type: ColumnType.Number, editable: false}),
    new Column({name: 'Name', prop: 'name', type: ColumnType.String, standard: true, order: 1}),
    new Column({name: 'Tagline', prop: 'tagline', type: ColumnType.String}),
    // new Column({name: 'Latitude', prop: 'lat', type: ColumnType.Number, editable: false}),
    // new Column({name: 'Longitude', prop: 'lng', type: ColumnType.Number, editable: false}),
    new Column({name: 'Sichtbarkeit', prop: 'visible_radius', type: ColumnType.Number, standard: true, order: 6, flexGrow: 0.2}),
    new Column({name: 'Adresse', prop: 'address', type: ColumnType.String}),
    new Column({name: 'PLZ', prop: 'zip', type: ColumnType.String, flexGrow: 0.2}),
    new Column({name: 'Stadt', prop: 'city', type: ColumnType.String, flexGrow: 0.5}),
    new Column({name: 'Telefon', prop: 'phone', type: ColumnType.String, flexGrow: 0.4}),
    // new Column({name: 'Gelöscht am', prop: 'deleted_at', type: ColumnType.DateTime, editable: false}),
    new Column({name: 'Erstellt am', prop: 'created_at', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Veröffentlicht am', prop: 'published_at', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Aktualisiert am', prop: 'updated_at', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Letzte Transaktion am', prop: 'last_transaction_date', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Letzte Registrierung am', prop: 'last_signup_date', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Ignoriert bis', prop: 'ignore_care_warning_until', type: ColumnType.DateTime, editable: false, flexGrow: 0.35}),
    new Column({name: 'Kontakt', prop: 'contact', type: ColumnType.String, flexGrow: 0.75}),
    new Column({name: 'Webseite', prop: 'website_url', type: ColumnType.String}),
    new Column({name: 'Instagram', prop: 'instagram_url', type: ColumnType.String}),
    new Column({name: 'Facebook', prop: 'facebook_url', type: ColumnType.String}),
    new Column({name: 'Yelp', prop: 'yelp_url', type: ColumnType.String}),
    new Column({name: 'Öffnungszeiten', prop: 'opening_hours', type: ColumnType.String}),
    // new Column({name: 'streetview_lat', prop: 'streetview_lat', type: ColumnType.Number, editable: false}),
    // new Column({name: 'streetview_lng', prop: 'streetview_lng', type: ColumnType.Number, editable: false}),
    // new Column({name: 'streetview_heading', prop: 'streetview_heading', type: ColumnType.Number, editable: false}),
    // new Column({name: 'streetview_pitch', prop: 'streetview_pitch', type: ColumnType.Number, editable: false}),
    new Column({name: 'Beschreibung', prop: 'description', type: ColumnType.String}),
    new Column({name: 'Demo', prop: 'demo', type: ColumnType.Boolean, flexGrow: 0.1}),
    // new Column({name: 'Blacklist f. Standorte in der Nähe', prop: 'nearby_business_blacklist', type: ColumnType.String}),
    new Column({name: 'Signups', prop: 'signups_count', type: ColumnType.Number, standard: true, order: 3, flexGrow: 0.3}),
    new Column({name: 'Memberships', prop: 'memberships_count', type: ColumnType.Number, standard: true, order: 4, flexGrow: 0.3}),
    new Column({name: 'Transaktionen', prop: 'transactions_count', type: ColumnType.Number, standard: true, order: 5, flexGrow: 0.3}),
    new Column({name: 'MC', prop: 'mc', type: ColumnType.MC, standard: true, order: 7, flexGrow: 0.1}),
    new Column({name: 'Salesperson #', prop: 'salesperson_id', type: ColumnType.Number, flexGrow: 0.1}),
    new Column({name: 'Supervisor #', prop: 'supervisor_id', type: ColumnType.Number, flexGrow: 0.1}),
    // Associated Columns
    new Column({
      name: 'Partner', prop: 'partner', association: 'belongs_to', columns: Partner.allColumns,
      editable: false, standard: true, standardProps: 'logo,name', order: 0
    }),
    new Column({name: 'Gebiet', prop: 'area', association: 'belongs_to', columns: Area.allColumns, editable: false}),
    new Column({name: 'Kunde', prop: 'client', association: 'belongs_to', columns: Client.allColumns, editable: false}),
  ];

  // Simple Columns
  id: number;
  name: string;
  partner_id: number;
  client_id: number;
  area_id: number;
  lat: number;
  lng: number;
  visible_radius: number;
  address: string;
  zip: string;
  city: string;
  phone: string;
  website_url: string;
  deleted_at: boolean;
  created_at: boolean;
  updated_at: boolean;
  contact: string;
  facebook_url: string;
  yelp_url: string;
  opening_hours: string;
  published_at: Date;
  disabled_at: Date;
  streetview_lat: number;
  streetview_lng: number;
  streetview_heading: number;
  streetview_pitch: number;
  tagline: string;
  description: string;
  instagram_url: string;
  demo: boolean;
  nearby_business_blacklist: string;
  supervisor_id: number;
  salesperson_id: number;
  // Associations
  partner: Partner;
  area: Area;
  client: Client;
  terminal: Terminal[];
  tablet: Tablet[];
  salesperson: Administrator;
  supervisor: Administrator;
  transaction: Transaction[];
  pos_session: PosSession[];
  signup: User[];
  merchant_access: MerchantAccess[];
  merchant: Merchant[];
  business_photo: BusinessPhoto[];
  membership: Membership[];
  user: User[];
  offer: Offer[];
  last_transaction_date: Date;
  last_signup_date: Date;
  ignore_care_warning_until: Date;
  pipedrive_deal_id: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);

    if (!!values['lat']) {
      this.lat = +values['lat'];
    }

    if (!!values['lng']) {
      this.lng = +values['lng'];
    }
    // if (this.ignore_care_warning_until) {
    //   this.ignore_care_warning_until = new Date(this.ignore_care_warning_until);
    // }
  }

  humanize() {
    return (this.partner && this.partner.name) ? `${this.name} (${this.partner.name})` : this.name;
  }

  getNearbyBusinessBlacklistIds() {
   if (!this.nearby_business_blacklist) {
     return [];
   }
    return this.nearby_business_blacklist.split(',').map(a => parseInt(a, 10));
  }

  isDisabled(): boolean {
    return this.disabled_at != null;
  }

  isPublished(): boolean {
    return this.published_at != null;
  }
}

export class BusinessList {
  count: number;
  data: Business[];

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}

export class BusinessStats {
  total_transactions: number;
  last_transaction: Date;
  average_transactions_per_day: number;
  total_memberships: number;
  total_campaigns: number;
  last_campaign_at: Date;
  campaigns: {total: number, partner: number, business: number, last_campaign: Date, last_business_campaign: Date};
  automations: { teaser: number, first_visit: number, growth: number, at_risk: number, lapsed: number, lost: number, birthday: number };

  constructor(values: Object = {}) {
    Object.assign(this, values);
    if (this.last_transaction != null) {
      this.last_transaction = new Date(this.last_transaction);
    }
    if (this.campaigns &&  this.campaigns.last_campaign != null) {
      this.campaigns.last_campaign = new Date(this.campaigns.last_campaign);
    }
    if (this.campaigns &&  this.campaigns.last_business_campaign != null) {
      this.campaigns.last_business_campaign = new Date(this.campaigns.last_business_campaign);
    }
  }
}
